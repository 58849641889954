import { ArrowForward } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  GetTransmissionsQueryGenerated,
  useGetTransmissionsQueryGenerated,
} from "../types/generated";

type Transmission = GetTransmissionsQueryGenerated["getTransmissions"][0];

interface Props {
  transmission: Transmission;
}

function TransmissionListItem({
  transmission: { id, title, date, body },
}: Props) {
  return (
    <Link
      to={id?.toString() || ""}
      state={{ transmission: { id, title, date, body } }}
    >
      <div className="transmission-item flex items-center justify-between px-4 py-2">
        <div className="flex flex-col">
          <div>
            <Typography variant="caption" className="opacity-50">
              {date}
            </Typography>
          </div>
          <div className="-mt-[10px]">
            <Typography variant="overline">{title}</Typography>
          </div>
        </div>
        <ArrowForward />
      </div>
    </Link>
  );
}

export function Transmissions() {
  const {
    data: transmissions,
    loading: isLoading,
    error: isError,
  } = useGetTransmissionsQueryGenerated();

  return (
    <div className="flex min-w-[100vw] flex-col lg:items-center">
      <div className="flex flex-col gap-4 lg:w-[750px]">
        {!isLoading &&
          !isError &&
          transmissions?.getTransmissions.map((transmission, index) => (
            <TransmissionListItem
              key={`${index}`}
              transmission={transmission}
            />
          ))}
      </div>
    </div>
  );
}
