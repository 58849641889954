import "./index.css";
import "./Navigation/styles.css";

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import Routing from "./Routing";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { MediaPlayerProvider } from "./Media/MediaPlayerProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material/styles";
import { NavigationProvider } from "./Navigation/NavigationProvider";
import { BassnectarTheme } from "./ui/Theme";
import ScrollToTop from "./ui/ScrollToTop";
import { AuthProvider } from "./Auth/AuthProvider";
import { AuthedApolloProvider } from "./Auth/AuthedApolloProvider";
import { NotificationsProvider } from "./Profile/NotificationsContext";
import { LiveEventProvider } from "./Events/LiveEventProvider";
import { Maintenance } from "./Routing/Maintenance";
import env from "react-dotenv";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        {env.MAINTENANCE === true ? (
          <ThemeProvider theme={BassnectarTheme}>
            <Routes>
              <Route path="*" element={<Maintenance />} />
            </Routes>
          </ThemeProvider>
        ) : (
          <AuthProvider>
            <AuthedApolloProvider>
              <NotificationsProvider>
                <MediaPlayerProvider>
                  <ScrollToTop />
                  <NavigationProvider>
                    <LiveEventProvider>
                      <ThemeProvider theme={BassnectarTheme}>
                        <Routing />
                      </ThemeProvider>
                    </LiveEventProvider>
                  </NavigationProvider>
                </MediaPlayerProvider>
              </NotificationsProvider>
            </AuthedApolloProvider>
          </AuthProvider>
        )}
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
