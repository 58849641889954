import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

interface NavigationContextParams {
  handleModal: (value: boolean) => void;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  navState: boolean;
  barNavigationUrls: string[];
  topNavigationUrls: string[];
  baseUrls: string[];
  cardIndex: number;
  handleCardIndexChange: (index: number) => void;
}

export const NavigationContext = createContext<NavigationContextParams>({
  handleModal: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  navState: false,
  barNavigationUrls: [],
  topNavigationUrls: [],
  baseUrls: [],
  cardIndex: 0,
  handleCardIndexChange: (index: number) => {},
});

export function NavigationProvider({ children }: PropsWithChildren) {
  const [lastPath, setLastPath] = useState("/");
  const [startY, setStartY] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [navState, setNavState] = useState(true);
  const [scrollNavigationUrls] = useState(["/", "/home"]);
  const [cardIndex, setCardIndex] = useState(3);

  const { pathname } = useLocation();

  const handleCardIndexChange = (index: number) => setCardIndex(index);

  const handleTouchStart = useCallback((event: TouchEvent) => {
    setStartY(event.touches[0].clientY);
  }, []);

  const handleWheel = useCallback(
    (event: WheelEvent) => {
      const nextNavState =
        !scrollNavigationUrls.includes(pathname) &&
        !isModalOpen &&
        event.deltaY > 0
          ? false
          : true;
      setNavState(nextNavState);
    },
    [isModalOpen, scrollNavigationUrls, pathname]
  );

  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      const currentY = event.touches[0].clientY;
      const newDistance = startY! - currentY;

      const nextNavState =
        !scrollNavigationUrls.includes(pathname) &&
        !isModalOpen &&
        newDistance > 0
          ? false
          : true;
      setNavState(nextNavState);
    },
    [startY, isModalOpen, scrollNavigationUrls, pathname]
  );

  const handleTouchEnd = useCallback(() => {
    setStartY(null);
  }, []);

  const reset = () => {
    setNavState(true);
    setStartY(null);
  };

  useEffect(() => {
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("wheel", handleWheel);

    if (pathname !== lastPath) {
      reset();
      setLastPath(pathname);
    }

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("wheel", handleWheel);
    };
  }, [handleTouchEnd, handleTouchStart, handleTouchMove, handleWheel]);

  const handleModal = (value: boolean) => setIsModalOpen(value);

  return (
    <NavigationContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        handleModal,
        navState,
        cardIndex,
        handleCardIndexChange,
        barNavigationUrls: ["/onboarding", "/signin"],
        topNavigationUrls: ["/onboarding"],
        baseUrls: [
          "/",
          "/home",
          "/profile",
          "/albums",
          "/community",
          "/listen",
        ],
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
}
