import { IconButton, Typography, Modal, Box, Select, MenuItem, SelectChangeEvent, Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useProcessKeyPurchaseMutationGenerated } from "../types/generated";
import { useContext, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import LoadingIcons from "react-loading-icons";
import {PaymentSelect} from "../ui/PaymentSelect";
import {Link} from "react-router-dom";

interface Props {
  open: boolean;
  quantity: number;
  onDismiss: () => void;
}

export function PurchaseKeysModal({ open, onDismiss }: Props) {
  const [purchaseKeys, { error }] = useProcessKeyPurchaseMutationGenerated({
    fetchPolicy: "network-only",
  });

  const { refreshUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [cardId, setCardId] = useState('');
  const [quantity, setQuantity] = useState("50");

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await purchaseKeys({
        variables: {
          input: {
            cardId,
            quantity: parseInt(quantity),
          },
        },
      });

      await refreshUser(false);
      onDismiss();
    } catch (err: any) {
      console.log(err);
    }
    setLoading(false);
  };

  const handlePayment = (id:string) => {
    console.log(id);
    setCardId(id);
  };
  
  const handleQuantity = (e:SelectChangeEvent) => {
    setQuantity(e.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className=" flex h-full w-full items-center justify-center">
        <Box className="h-{350px} tos-body-bkg relative mx-6 w-full rounded-2xl p-6">
          <IconButton
            onClick={onDismiss}
            sx={{
              color: "#67F99B",
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 999,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="z-0 mt-12 flex items-center justify-center">
            <div className="absolute z-0 h-[140px] w-full bg-frequency"></div>
            <Typography id="modal-modal-title" variant="h1">
              Buy Keys
            </Typography>
          </div>
          {error && (
            <Typography variant="body1">
              Sorry we were not able to purchase your keys.
            </Typography>
          )}
          {!loading && (
            <div className="my-4 mt-12 flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <Typography variant="caption2">Key Quantity:</Typography>
                <Select onChange={handleQuantity} value={quantity}>
                  <MenuItem value="50">
                    <Typography variant="h6" style={{ color: 'black' }}>50 Keys - $5.00</Typography>
                  </MenuItem>
                  <MenuItem value="100">
                    <Typography variant="h6" style={{ color: 'black' }}>100 Keys - $10.00</Typography>
                  </MenuItem>
                </Select>
              </div>
              <div className="flex flex-col gap-1">
                <Typography variant="caption2">Payment Method:</Typography>
                <PaymentSelect onChange={handlePayment} value={cardId} />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <Link to="/profile/accountInfo">
                  <Button variant="text">Manage Payment Methods</Button>
                </Link>
              </div>
              <Button
                variant="bassnectar"
                onClick={handleSubmit}
                fullWidth
                disabled={cardId === ""}
              >
                Buy Keys
              </Button>
            </div>
          )}
          {loading && (
            <div>
              <div style={{ margin: "0 auto", width: "200px" }}>
                <LoadingIcons.Bars width="198px" />
              </div>
            </div>
          )}
        </Box>
      </div>
    </Modal>
  );
}
