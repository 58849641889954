import { ArrowBack } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

export function TransmissionDetails() {
  const {
    state: { transmission },
  } = useLocation();

  return (
    <div className="flex min-w-[100vw] flex-col lg:items-center">
      <div className="transmission-body mb-4 flex flex-col px-4 py-2 lg:max-w-[750px]">
        <div className="mt-[10px]">
          <Typography variant="caption" className="opacity-50">
            {transmission.date}
          </Typography>
        </div>
        <div className="-mt-[10px]">
          <Typography variant="overline">{transmission.title}</Typography>
        </div>
        <div className="mt-4">
          <div dangerouslySetInnerHTML={{ __html: transmission.body }} />
        </div>
      </div>
      <div className="flex justify-center">
        <Link to="/thoughts/transmissions">
          <Button variant="bassnectar" startIcon={<ArrowBack />}>
            Back To Transmissions
          </Button>
        </Link>
      </div>
    </div>
  );
}
