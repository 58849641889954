import { Typography } from "@mui/material";
import Keys from "../assets/treasure/keys.png";
import LoadingIcons from "react-loading-icons";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import {
  UserTreasure,
  useGetTreasureLazyQueryGenerated,
} from "../types/generated";
import { TreasureItem } from "./TreasureItem";

export function TreasureBox() {
  const [treasure, setTreasure] = useState<UserTreasure | undefined>(undefined);
  const { user } = useContext(AuthContext);

  const [getTreasure, { loading }] = useGetTreasureLazyQueryGenerated();

  useEffect(() => {
    const fetchTreasure = async () => {
      try {
        if (user) {
          const result = await getTreasure({
            variables: { email: user.email },
          });

          const treasure = result.data?.getUser.treasure;
          setTreasure(treasure);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchTreasure();
  }, [user, getTreasure]);

  return (
    <div className="tos-treasure-bkg relative mb-[40px] w-full rounded-lg">
      {/* <div className="absolute left-0 top-0 z-0 h-full w-full rounded-xl bg-white mix-blend-overlay"></div> */}
      {loading ? (
        <div className="flex flex-col items-center">
          <div style={{ margin: "0 auto", width: "200px" }}>
            <LoadingIcons.Bars width="200px" />
          </div>
          <Typography variant="h6">Loading your treasure</Typography>
        </div>
      ) : (
        <div className="flex flex-col p-4 text-center">
          <Typography variant="subheading">Your Treasure Box</Typography>
          <div className="z-1 relative mt-2 flex flex-wrap justify-around">
            {treasure &&
              treasure.items &&
              treasure.items.map((item) => <TreasureItem item={item} />)}
            {treasure && (
              <div className="mr-2 flex h-[100px] flex-col items-center">
                <img src={Keys} alt="og-reward" style={{ height: 75 }} />
                <Typography variant="caption">{`${treasure.keyCount} Keys`}</Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
