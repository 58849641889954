import ReactCodeInput from "react-code-input";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { ChangeEvent, useContext, useReducer, useState } from "react";
import { Navigate } from "react-router-dom";
import { object, string, ValidationError } from "yup";
import { SectionHero } from "../ui/components/SectionHero";
import { AuthContext } from "./AuthProvider";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ErrorToast } from "./ErrorToast";

interface ConfirmFields {
  code: string;
  username: string;
  password: string;
  confirmPassword: string;
}

interface Action {
  key: string;
  value: string | boolean;
}

const passwordSchema = object({
  password: string().min(8).required("Password is required"),
});

export function ForgotPasswordConfirmationForm() {
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { forgotPasswordSubmit, forgotPasswordUsername } =
    useContext(AuthContext);

  const reducer = (state: ConfirmFields, action: Action) => ({
    ...state,
    [action.key]: action.value,
  });

  const [state, dispatch] = useReducer(reducer, {
    code: "",
    username: forgotPasswordUsername || "",
    password: "",
    confirmPassword: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const handleCode = (value: string) => {
    dispatch({ key: "code", value });
  };

  const handleSubmit = async () => {
    try {
      await passwordSchema.validate(state, {
        abortEarly: false,
      });

      if (state.password !== state.confirmPassword) {
        throw new ValidationError(
          "Passwords must match",
          "Passwords must match",
          "passwordConfirmation"
        );
      }

      const success = await forgotPasswordSubmit(state);
      setSuccess(success);
    } catch (err) {
      const errors: Record<string, string[]> = {};
      if (err instanceof ValidationError) {
        console.log("validation error");
        console.log(err);
        if (err.inner?.length > 0) {
          err.inner.forEach((e: ValidationError) => {
            if (errors[e.path!]?.length > 0) {
              console.log(e.message);
              errors[e.path!].push(e.message);
            } else {
              console.log(e.message);
              errors[e.path!] = [e.message];
            }
          });
        } else {
          errors[err.path!] = [err.message];
        }
        setErrors(errors);
      } else {
        console.log(err);
      }
    }
  };

  const handleInput =
    (key: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch({ key, value: e.target.value });
    };

  return (
    <div className="flex flex-col lg:items-center">
      {success && <Navigate to="/auth/signin" />}
      <SectionHero title="Password Reset" />
      <div className="lg:max-w-[750px]">
        <ErrorToast />
        <div className="px-6">
          <div className="mb-4 flex flex-col gap-2">
            <Typography variant="body1">
              To secure your account, we need to confirm it's really you. Enter
              the code we sent to your email to verify your account and enter
              your new password.
            </Typography>
            <ReactCodeInput
              name="confirmationCode"
              type="number"
              fields={6}
              inputMode="numeric"
              onChange={handleCode}
            />
          </div>
          <div className="flex flex-col gap-2">
            {!forgotPasswordUsername && (
              <>
                <TextField
                  variant="filled"
                  onChange={handleInput("username")}
                  label="Username"
                  value={state.username}
                  error={errors.username?.length > 0}
                />
                {errors.username && (
                  <Typography variant="body1">{errors.username}</Typography>
                )}
              </>
            )}
            <TextField
              variant="filled"
              type={showPassword ? "text" : "password"}
              onChange={handleInput("password")}
              label="Password"
              value={state.password}
              error={errors.password?.length > 0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password && (
              <Typography variant="body1">{errors.password}</Typography>
            )}
            <TextField
              variant="filled"
              type={showConfirmPassword ? "text" : "password"}
              onChange={handleInput("confirmPassword")}
              label="Confirm Password"
              value={state.confirmPassword}
              error={errors.confirmPassword?.length > 0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleConfirmPasswordVisibility}>
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.passwordConfirmation && (
              <Typography variant="body1">
                {errors.passwordConfirmation}
              </Typography>
            )}
            <Button variant="bassnectar" onClick={handleSubmit}>
              Reset Your Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
