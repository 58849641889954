import { Button, TextField, Typography } from "@mui/material";
import { ChangeEvent, useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { SectionHero } from "../ui/components/SectionHero";
import { AuthContext } from "./AuthProvider";
import { ErrorToast } from "./ErrorToast";

export function ForgotPasswordForm() {
  const [value, setValue] = useState("");
  const [success, setSuccess] = useState(false);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
  };

  const { clearErrors, forgotPassword } = useContext(AuthContext);

  const handleSubmit = async () => {
    clearErrors();
    const success = await forgotPassword(value);
    setSuccess(success);
  };

  return (
    <div className="flex w-screen flex-col lg:items-center">
      <SectionHero title="Forgot Password" />
      <div className="w-full lg:max-w-[750px]">
        {success && <Navigate to="/auth/reset-password" />}
        <ErrorToast />
        <div className="mt-6 flex flex-col gap-4 px-6">
          <div>
            <Typography variant="h6">Please provide your email</Typography>
            <TextField
              variant="filled"
              fullWidth
              label="E-mail"
              onChange={handleChange}
            />
          </div>
          <Button variant="bassnectar" fullWidth onClick={handleSubmit}>
            Send Recovery Email
          </Button>
        </div>
      </div>
    </div>
  );
}
