import { useParams, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Typography } from "@mui/material";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkIcon from "@mui/icons-material/Link";
import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useGetCharityQueryGenerated } from "../types/generated";

export const CharityDetails = () => {
  const { charityId } = useParams();

  const {
    data,
    loading: isLoading,
    error: isError,
  } = useGetCharityQueryGenerated({
    variables: { id: charityId ? parseInt(charityId) : 4 },
  });

  return (
    <>
      {isLoading && <div>Loading...</div>}
      {isError && <div>Error!</div>}
      {data && (
        <div className="artistsDetail relative -mt-[100px]">
          <div className="relative pb-8">
            <div className="relative flex flex-col">
              <div className="image-container relative">
                <img
                  alt={data?.getCharity.name ?? undefined}
                  src={data?.getCharity.pageHero ?? undefined}
                  className="image-fade w-full"
                />
              </div>
              <div className="z-[1] -mt-8 flex w-full flex-col px-5 pb-6">
                <h3 className="text-[30px] font-bold leading-[120%]">
                  {data?.getCharity.name}
                </h3>
                <span className="mb-2 text-[14px] font-semibold leading-normal">
                  {data?.getCharity.title}
                </span>
                <div className="flex items-center">
                  <LinkIcon className="rotate-[135deg] text-[16px] text-white" />
                  <span
                    onClick={() => {
                      window.open(data?.getCharity.websiteUrl ?? undefined);
                    }}
                    className="pl-2 text-[14px] font-semibold"
                  >
                    {data?.getCharity.websiteUrl}
                  </span>
                </div>
              </div>
              <div className="mb-6 px-5">
                <Swiper
                  grabCursor={true}
                  slidesPerView={1}
                  spaceBetween={20}
                  loop={true}
                  pagination={{
                    el: ".pagination-container",
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {/* {data?.getCharity.works.map((work: any, index: number) => {
                    return (
                      <SwiperSlide
                        key={index}
                        className="flex flex-col justify-center"
                      >
                        <img
                          alt={data?.getCharity.name ?? undefined}
                          src={work.url}
                        />
                      </SwiperSlide>
                    );
                  })} */}
                  {data.getCharity.works && (
                    <img
                      alt={data?.getCharity.name ?? undefined}
                      src={data.getCharity.works}
                    />
                  )}
                </Swiper>
                <div className="pagination-container mt-4 w-full" />
              </div>
            </div>
          </div>
          <div className="pb-8">
            <div className="relative flex flex-col px-5">
              <Typography variant="h5">
                <div className="mb-4">About {data?.getCharity.name}</div>
              </Typography>
              {data?.getCharity.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.getCharity.description,
                  }}
                />
              )}

              {data?.getCharity.instagramUrl && (
                <div className={"mt-10"}>
                  <Typography variant="h5">
                    Connect with {data?.getCharity.name}
                  </Typography>
                  <div style={{ display: "flex" }}>
                    {data?.getCharity.instagramUrl && (
                      <Link
                        className="mr-[15px]"
                        to={data?.getCharity.instagramUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <InstagramIcon />
                      </Link>
                    )}
                  </div>
                </div>
              )}
              {data?.getCharity.donateUrl && (
                <div className={"mt-10"}>
                  <Typography variant="h5">
                    Support {data?.getCharity.name}
                  </Typography>
                  <Link to={data?.getCharity.donateUrl}>
                    <Button
                      className="button text-[20px] font-bold"
                      endIcon={<ArrowForward className="text-white" />}
                    >
                      Donate Now
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
