import { IconButton, Typography, Modal, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useUpgradeSubscriptionMutationGenerated } from "../types/generated";
import { useContext, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import {PaymentSelect} from "../ui/PaymentSelect";
import {Link} from "react-router-dom";
import LoadingIcons from "react-loading-icons";

interface Props {
  open: boolean;
  onDismiss: () => void;
}

export function UpgradeSubscriptionModal({ open, onDismiss }: Props) {
  const [loading, setLoading] = useState(false);
  const [cardId, setCardId] = useState("");

  const { waitForUserGroup } = useContext(AuthContext);

  const [upgradeSubscription, { error }] =
    useUpgradeSubscriptionMutationGenerated();
  // const [coupon, setCoupon] = useState("");

  const handlePayment = (cardId:string) => setCardId(cardId);

  const handleUpgradeSubscription = async () => {
    setLoading(true);
    try {
      await upgradeSubscription({ variables: {
        cardId,
      } });
      await waitForUserGroup('gold');
      onDismiss();
      setLoading(false);
    } catch (err) {
      //Do some error shit
    }
  };

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex h-full w-full items-center justify-center">
        <Box className="h-{350px} bg-light-gold rounded-md mx-6 w-full p-6">
          <div className="flex items-center justify-between">
            <Typography id="modal-modal-title" variant="h4" component="h2" color="black">
              Upgrade To Gold
            </Typography>
            <IconButton onClick={onDismiss} sx={{ color: "#000" }}>
              <CloseIcon />
            </IconButton>
          </div>
          {loading && (
            <div>
              <div style={{ margin: "0 auto", width: "200px" }}>
                <LoadingIcons.Bars width="198px" />
                <Typography variant="h6">Upgrading your account</Typography>
              </div>
            </div>
          )}
          {!loading && (
            <div className="flex flex-col gap-2 my-4">
              <Typography variant="h5" color="black">
                1 Year of Access for $110
              </Typography>

              <Typography variant="body1" sx={{ color: '#000' }}>
                In order to thank for for upgrading your monthly
                subscription to annual, we will credit you this months
                membership fee of $10. Your annual membership will
                automatically renew at full price ($120) next year.
              </Typography>
              <div className="flex flex-col gap-1 w-full">
                <Typography style={{ color: '#000' }} variant="caption2">Payment Method:</Typography>
                <PaymentSelect onChange={handlePayment} value={cardId} />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <Link to="/profile/accountInfo">
                  <Button variant="text" sx={{ color: "#000", textDecoration: 'underline' }}>Manage Payment Methods</Button>
                </Link>
              </div>

              {error && (
                <Typography variant="body1">
                  Sorry we were not able to upgrade your account.
                </Typography>
              )}
            </div>
          )}
          <Button
            variant="bassnectar"
            onClick={handleUpgradeSubscription}
            fullWidth
            disabled={cardId === "" || loading}
          >
            UNLOCK GOLD
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
