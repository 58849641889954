import env from "react-dotenv";
import { Link, useParams } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { UpcomingEvents } from "./Upcoming";
import { EventSurvey } from "./EventSurvey";
import { ShowTrailer } from "./ShowTrailer";
import { AudioArcadeModal } from "../Arcade/AudioArcadeModal";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { SubscriptionModal } from "../Sales/SubscriptionModal";
import {
  useActivateArcadeSessionMutationGenerated,
  useGetEventDetailsSuspenseQueryGenerated,
  StrapiTicketAuthType,
} from "../types/generated";
import { Gradient } from "../ui/Gradient";
import { userIsUnlocked } from "../Auth/utils";
import { useGetTicketmasterPresaleCodeLazyQueryGenerated } from "../types/generated";
import { TicketmasterCodeModal } from "../Sales/TicketmasterCodeModal";
import { LiveEventContext } from "./LiveEventProvider";
import { EventPrivacy } from "./EventPrivacy";
import WidgetBot from "@widgetbot/react-embed";
import { MediaPlayerContext } from "../Media/MediaPlayerProvider";

export const EventDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const [modalReason, setModalReason] = useState("activate");
  const [subModalCondition, setSubModalCondition] = useState<
    string | undefined
  >(undefined);

  const [activateArcadeSession] = useActivateArcadeSessionMutationGenerated();

  const { user } = useContext(AuthContext);

  const arcadePaths = {
    "13": "etr",
    "14": "pinball",
    "15": "ghostrun",
    "29": "bounce",
  };

  const gameIds = {
    "13": "enjoyTheRide",
    "14": "pinball",
    "15": "ghostrun",
    "29": "bounce",
  };

  const { eventId } = useParams();

  const gameInfo =
    eventId === "13" || eventId === "14" || eventId === "15" || eventId === "29"
      ? { id: gameIds[eventId], path: arcadePaths[eventId] }
      : undefined;

  const { data } = useGetEventDetailsSuspenseQueryGenerated({
    variables: { eventId: eventId ? parseInt(eventId) : 1 },
  });

  const { doorsOpen, event } = useContext(LiveEventContext);

  // const [crate, setCrate] = useState<any>(undefined);
  // const [init, setInit] = useState(true);

  // useEffect(() => {
  //   const setup = async () => {
  //     const result = await import("@widgetbot/crate");
  //     const Crate = await result.cdn();
  //     setCrate(
  //       new Crate({
  //         server: "784687598000930847",
  // channel:
  //   data.getEventDetails?.type &&
  //   data.getEventDetails?.type == "MIXTAPE"
  //     ? "1134635191277731890"
  //     : "1065054661360484414",
  //         css: `.button {
  //         margin-bottom: 4rem;
  //       }; .embed { margin-bottom: 4rem;};`,
  //         glyph: ["/logo512.png", "100%"],
  //         color: "#000000",
  //         notifications: false,
  //         shard: "https://emerald.widgetbot.io",
  //         username: user?.username,
  //       })
  //     );
  //   };
  //   if (
  // doorsOpen &&
  // user &&
  // event &&
  // data &&
  // userIsUnlocked(user) &&
  // data.getEventDetails.id === event.id &&
  //     crate == undefined &&
  //     init == false
  //   ) {
  //     setup();
  //   }

  //   setInit(false);

  //   return () => {
  //     if (crate && init == false) {
  //       setCrate(undefined);
  //       (window as any).crate = null;
  //       delete (window as any).crate;
  //       (window as any).Crate = null;
  //       delete (window as any).Crate;
  //       const widgetElements = document.querySelectorAll("widgetbot-crate");

  //       widgetElements.forEach((element) => {
  //         element.remove();
  //       });
  //     }
  //   };
  // }, [user, data, event, doorsOpen, crate, init]);

  const closeModal = () => {
    setModalOpen(false);
    setTicketModalOpen(false);
  };

  const handleSubmit = async () => {
    if (!gameInfo || !gameInfo.id) return;

    try {
      await activateArcadeSession({
        variables: {
          gameId: gameInfo.id as any,
        },
      });
      if (gameInfo.path) {
        window.location.href = `${env.ARCADE_URL}/${gameInfo?.path}`;
      }
    } catch (err) {
      //console.log(err);
      setModalOpen(true);
      setModalReason("error");
    }
  };

  const handleClose = () => setSubModalCondition(undefined);

  const handleTicketClickthrough = async () => {
    if (
      data.getEventDetails.ticketPortalInfo &&
      data.getEventDetails.ticketPortalInfo?.ticketAuthType ===
        StrapiTicketAuthType.CODE
    ) {
      setTicketModalOpen(true);
    } else {
      window.location.href = `${env.TICKET_URL}/${eventId}`;
    }
  };

  const { nowPlayingIndex } = useContext(MediaPlayerContext);

  return (
    <>
      <EventPrivacy event={data?.getEventDetails} />
      <AudioArcadeModal
        reason={modalReason}
        onDismiss={closeModal}
        onSubmit={handleSubmit}
        open={modalOpen}
      />
      <SubscriptionModal
        open={subModalCondition !== undefined}
        reason={subModalCondition}
        onDismiss={handleClose}
      />
      <TicketmasterCodeModal
        url={data?.getEventDetails.ticketPortalInfo?.ticketPortalBaseUrl}
        open={ticketModalOpen}
        onDismiss={closeModal}
      />
      {data.getEventDetails.gradient && (
        <Gradient gradient={data.getEventDetails.gradient} />
      )}
      {data && (
        <div className="artistsDetail relative -mt-[100px]">
          <div className="relative pb-8">
            <div className="relative flex flex-col">
              <div className="image-container relative">
                <img
                  alt={data.getEventDetails.name ?? undefined}
                  src={data.getEventDetails.pageHero ?? undefined}
                  className="image-fade w-full"
                />
              </div>
              <div className="mx-5">
                <ShowTrailer eventType={data.getEventDetails.type} />
              </div>
              <div className="px-5">
                <div className="pagination-container w-full" />
              </div>
            </div>
          </div>
          <div>
            <div className="relative flex flex-col px-5">
              <Typography variant="h4">
                <div className="mb-4">About {data.getEventDetails.name}</div>
              </Typography>

              {data.getEventDetails.callToAction &&
                data.getEventDetails.callToAction.ctaType === "link" && (
                  <div className="mt-8">
                    <Link to={data.getEventDetails.callToAction.ctaPath ?? ""}>
                      <Button variant="bassnectar" fullWidth>
                        {data.getEventDetails.callToAction.ctaLabel}
                      </Button>
                    </Link>
                  </div>
                )}

              {data.getEventDetails.callToAction &&
                data.getEventDetails.callToAction.ctaType === "tickets" && (
                  <Button
                    variant="bassnectar"
                    onClick={
                      userIsUnlocked(user)
                        ? handleTicketClickthrough
                        : () => setSubModalCondition("locked")
                    }
                  >
                    {data.getEventDetails.callToAction.ctaLabel}
                  </Button>
                )}

              {data.getEventDetails.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.getEventDetails.description,
                  }}
                />
              )}
              {doorsOpen &&
                user &&
                event &&
                data &&
                userIsUnlocked(user) &&
                data.getEventDetails.id === event.id && (
                  <WidgetBot
                    server="784687598000930847"
                    channel={
                      data.getEventDetails?.type &&
                      data.getEventDetails?.type == "MIXTAPE"
                        ? "1134635191277731890"
                        : "1065054661360484414"
                    }
                    width={"100%"}
                    height={"450px"}
                    shard="https://emerald.widgetbot.io"
                    username={user?.username}
                    className={nowPlayingIndex > -1 ? "mb-28" : "mb-12"}
                  />
                )}
            </div>
            {data.getEventDetails.surveys &&
              data.getEventDetails.surveys.length > 0 && (
                <div className="mt-10 px-5">
                  <EventSurvey surveys={data.getEventDetails.surveys} />
                </div>
              )}
            <UpcomingEvents />
          </div>
        </div>
      )}
    </>
  );
};
