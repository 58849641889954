import env from "react-dotenv";
import { useParams } from "react-router-dom";
import { Typography, Button, CircularProgress } from "@mui/material";
import { AudioArcadeModal } from "../Arcade/AudioArcadeModal";
import { useEffect, useState } from "react";
import { formatMilliseconds } from "../utils/formatSecondsInMinutes";
import { KeyIcon } from "../Arcade/KeyIcon";
import { Launch } from "@mui/icons-material";
import { SubscriptionModal } from "../Sales/SubscriptionModal";
import {
  useActivateArcadeSessionMutationGenerated,
  useVerifyArcadeSessionQueryGenerated,
  useGetArcadeDetailsSuspenseQueryGenerated,
} from "../types/generated";

export const ArcadeDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalReason, setModalReason] = useState("activate");
  const [subModalCondition, setSubModalCondition] = useState<
    string | undefined
  >(undefined);
  const [actionLabel, setActionLabel] = useState("Access Game With Key");
  const [activeSession, setActiveSession] = useState(false);

  const [activateArcadeSession] = useActivateArcadeSessionMutationGenerated();

  // const arcadePaths = {
  //   "13": "etr",
  //   "14": "pinball",
  //   "15": "ghostrun",
  //   "29": "bounce",
  // };

  // const gameIds = {
  //   "13": "enjoyTheRide",
  //   "14": "pinball",
  //   "15": "ghostrun",
  //   "29": "bounce",
  // };

  const { arcadeId } = useParams();

  // const gameInfo =
  //   arcadeId === "13" ||
  //   arcadeId === "14" ||
  //   arcadeId === "15" ||
  //   arcadeId === "29"
  //     ? { id: gameIds[arcadeId], path: arcadePaths[arcadeId] }
  //     : undefined;

  const { data } = useGetArcadeDetailsSuspenseQueryGenerated({
    variables: { arcadeId: arcadeId ? parseInt(arcadeId) : 1 },
  });

  const { data: keyData, loading: keyLoading } =
    useVerifyArcadeSessionQueryGenerated({
      variables: { gameId: data.getArcadeDetails.gameId ?? "" },
    });

  useEffect(() => {
    if (
      keyData &&
      keyData?.verifyArcadeSession.keyId &&
      keyData?.verifyArcadeSession.elapsedTime
    ) {
      const remaining = 14400000 - keyData.verifyArcadeSession.elapsedTime;
      setActiveSession(true);
      setActionLabel(`Resume session (${formatMilliseconds(remaining)})`);
    }
  }, [keyData, data]);

  const closeModal = () => setModalOpen(false);
  const openModal = () => {
    if (activeSession && data.getArcadeDetails) {
      window.location.href = `${env.ARCADE_URL}/${data.getArcadeDetails.id}`;
    } else {
      setModalOpen(true);
    }
  };

  const handleSubmit = async () => {
    if (!data.getArcadeDetails.id || !data.getArcadeDetails.gameId) return;

    try {
      await activateArcadeSession({
        variables: {
          gameId: data.getArcadeDetails.gameId,
        },
      });
      window.location.href = `${env.ARCADE_URL}/${data.getArcadeDetails?.id}`;
    } catch (err) {
      //console.log(err);
      setModalOpen(true);
      setModalReason("error");
    }
  };

  const handleClose = () => setSubModalCondition(undefined);

  return (
    <>
      <AudioArcadeModal
        reason={modalReason}
        onDismiss={closeModal}
        onSubmit={handleSubmit}
        text={data?.getArcadeDetails?.modalText}
        open={modalOpen}
      />
      <SubscriptionModal
        open={subModalCondition !== undefined}
        reason={subModalCondition}
        onDismiss={handleClose}
      />

      {data && (
        <div className="artistsDetail relative -mt-[100px]">
          <div className="relative pb-8">
            <div className="relative flex flex-col">
              <div className="image-container relative">
                <img
                  alt={data.getArcadeDetails.name ?? undefined}
                  src={data.getArcadeDetails.pageHero ?? undefined}
                  className="image-fade w-full"
                />
              </div>
              <div className="px-5">
                <div className="pagination-container w-full" />
              </div>
            </div>
          </div>
          <div>
            <div className="relative flex flex-col px-5">
              <Typography variant="h4">
                <div className="mb-4">About {data.getArcadeDetails.name}</div>
              </Typography>

              <div className="my-4">
                <Button
                  variant="bassnectar"
                  startIcon={keyLoading ? <CircularProgress /> : undefined}
                  endIcon={
                    keyLoading ? undefined : activeSession ? (
                      <Launch />
                    ) : (
                      <KeyIcon />
                    )
                  }
                  onClick={openModal}
                  fullWidth
                >
                  {keyLoading ? "" : actionLabel}
                </Button>
              </div>

              {data.getArcadeDetails.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.getArcadeDetails.description,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
