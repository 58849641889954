import env from "react-dotenv";
import { Amplify, Auth, Hub, Storage } from "aws-amplify";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import awsconfig from "../aws-exports.js";
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { UserSignUpParams } from "./types";
import { User } from "../types/User";
import { useNavigate } from "react-router-dom";
import { useGetSignedCookies } from "../utils/hooks";

Amplify.configure({
  ...awsconfig,
  Auth: {
    cookieStorage: {
      domain: env.AMPLIFY_COOKIE_DOMAIN,
      path: "/",
      expires: 365,
      sameSite: "strict",
      secure: env.AMPLIFY_COOKIE_DOMAIN !== "localhost",
    },
  },
});

const jwtVerifier = CognitoJwtVerifier.create({
  userPoolId: awsconfig.aws_user_pools_id,
  tokenUse: "id",
  clientId: awsconfig.aws_user_pools_web_client_id,
});

type AuthContextParams = {
  authenticating: boolean;
  user: User | null;
  forgotPasswordUsername?: string;
  accessToken?: string;
  signOut: () => Promise<void>;
  superSignOut: () => Promise<void>;
  signIn: (username: string, password: string) => Promise<boolean>;
  signUp: (values: UserSignUpParams) => Promise<boolean>;
  verifyAttribute: (attribute: string, code: string) => Promise<boolean>;
  confirmSignUp: (username: string, code: string) => Promise<boolean>;
  refreshUser: (nav: boolean) => Promise<boolean>;
  loggedIn: boolean;
  errors?: string[];
  clearErrors: () => void;
  setErrors: (errs: string[]) => void;
  updateUser: (values: any) => Promise<boolean>;
  updatePicture: (file: File) => Promise<boolean>;
  changePassword: (values: any) => Promise<boolean>;
  forgotPassword: (username: string) => Promise<boolean>;
  forgotPasswordSubmit: (values: any) => Promise<boolean>;
  getIdToken: () => Promise<string | undefined>;
  getAccessToken: () => Promise<string | undefined>;
  tempUsername: string | null;
  waitForUserGroup: (name:string) => Promise<User>;
  waitForUserAttribute: (attribute:string) => Promise<User>;
};

export const AuthContext = createContext<AuthContextParams>({
  authenticating: false,
  user: null,
  forgotPasswordUsername: undefined,
  accessToken: undefined,
  signOut: async () => {},
  superSignOut: async () => {},
  signIn: async () => false,
  signUp: async () => false,
  verifyAttribute: async () => false,
  confirmSignUp: async () => false,
  loggedIn: false,
  errors: [],
  clearErrors: () => {},
  setErrors: (errs) => {},
  updatePicture: async (file) => false,
  updateUser: async (values) => false,
  refreshUser: async (nav) => false,
  changePassword: async (values) => false,
  forgotPassword: async (username) => false,
  forgotPasswordSubmit: async (values) => false,
  getIdToken: async () => "",
  getAccessToken: async () => "",
  tempUsername: null,
  waitForUserGroup: (name) => { return new Promise((resolve) => {}) },
  waitForUserAttribute: (attr) => { return new Promise((resolve) => {}) },
});

export function AuthProvider({ children }: PropsWithChildren) {
  const [authenticating, setAuthenticating] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [forgotPasswordUsername, setForgotPasswordUsername] = useState<
    string | undefined
  >();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [loggedIn, setLoggedIn] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [tempUsername, setTempUsername] = useState<string | null>(null);

  const navigate = useNavigate();
  const getSignedCookies = useGetSignedCookies();

  const handleErrors = (errs: string[]) => setErrors(errs);

  const waitForUserGroup = async (name:string): Promise<User> => {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(`User did not get assigned the ${name} group!`);
      }, 10000);
      const timer = setInterval(async () => {
        const currentUser = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        const user = await parseUser(currentUser);
        console.log(user);
        if(user.groups.includes(name)) {
          handleUser(user);
          clearInterval(timer);
          clearTimeout(timeout);
          resolve(user);
        }
      }, 1000);
    });
  };

  const waitForUserAttribute = async (attribute:string): Promise<User> => {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(`User did not get assigned the ${attribute} attribute!`);
      }, 10000);

      const timer = setInterval(async () => {
        const currentUser = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        const user = await parseUser(currentUser);
        console.log(user);
        if(user[attribute] !== undefined) {
          handleUser(user);
          clearInterval(timer);
          clearTimeout(timeout);
          resolve(user);
        }
      }, 1000);
    });
  }

  const handleUser = useCallback(async (user: any) => {
    setLoggedIn(true);
    setUser(user);
    setAccessToken(user.accessToken);
    getSignedCookies(user.accessToken);
  }, [getSignedCookies]);

  const parseUser = async (user: any, token?: string, idToken?: string) => {
    const t = token || user.signInUserSession.getAccessToken().getJwtToken();
    const idt = idToken || user.signInUserSession.getIdToken().getJwtToken();
    const decoded = await jwtVerifier.verify(idt);

    const out = {
      ...user.attributes,
      id: user.attributes.sub,
      username: user.attributes.preferred_username ?? user.username,
      picture: user.attributes.picture,
      groups: decoded["cognito:groups"],
      squareCustomerId: decoded["custom:squareCustomerId"],
      discordId: decoded["custom:discordId"],
      accessToken: t,
      idToken: idt,
    };
    return out;
  };

  useEffect(() => {
    const cancel = Hub.listen("auth", async ({ payload }) => {
      const { event } = payload;
      let user;
      let nextErrors;
      switch (event) {
        case "autoSignIn":
          console.log("auto sign in");
          user = await parseUser(payload.data);
          handleUser(user);
          break;

        case "autoSignIn_failure":
          console.log("autoSignIn_failure");
          nextErrors = errors.concat([payload.data.message]);
          setErrors(nextErrors);
          navigate("/auth/signin");
          break;

        case "signIn":
          console.log("signIn");

          user = await parseUser(payload.data);
          handleUser(user);
          break;

        case "signIn_failure":
          console.log("signIn_failure");
          nextErrors = errors.concat([payload.data.message]);
          setErrors(nextErrors);
          if(payload.data.code === 'UserNotConfirmedException') {
            navigate("/auth/create-account/confirm");
          } else {
            navigate("/auth/signin");
          }
          break;
      }
    });
    return () => cancel();
  }, [errors, handleUser, navigate]);

  const clearCognitoCookies = () => {
    const cookieNames = document.cookie.split(/=[^;]*(?:;\s*|$)/);
    console.log(cookieNames);
    cookieNames.forEach((name: string, i: number) => {
      if (name.indexOf("CognitoIdentityServiceProvider") !== -1) {
        document.cookie =
          name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      }
    });
  };

  useEffect(() => {
    setAuthenticating(true);
    const getCurrentUser = async () => {
      try {
        console.log("getting current user");
        const currentUser = await Auth.currentUserInfo();
        const currentSession = await Auth.currentSession();
        const token = currentSession.getAccessToken().getJwtToken();
        const idToken = currentSession.getIdToken().getJwtToken();
        if (currentUser) {
          const user = await parseUser(currentUser, token, idToken);
          handleUser(user);
        }
        setAuthenticating(false);
      } catch (err: any) {
        clearCognitoCookies();
        console.log(`no user ${err.message}`);
        setAuthenticating(false);
      }
    };
    getCurrentUser();
  }, [handleUser]);

  const clearErrors = () => setErrors([]);

  const updatePicture = async (file: File) => {
    try {
      const { key } = await Storage.put(`${user?.id}/avatar`, file, {
        level: "public",
      });
      await updateUser({ picture: key });

      return true;
    } catch (err: any) {
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
      return false;
    }
  };

  const updateUser = async (values: any) => {
    try {
      clearErrors();

      let currentUser = await Auth.currentAuthenticatedUser();

      await Auth.updateUserAttributes(currentUser, values);

      currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const user = await parseUser(currentUser);

      setUser(user);
      return true;
    } catch (err: any) {
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
      return false;
    }
  };

  const changePassword = async (values: any) => {
    try {
      const { oldPassword, newPassword } = values;
      clearErrors();
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      return true;
    } catch (err: any) {
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
      return false;
    }
  };

  const forgotPassword = async (username: string) => {
    try {
      clearErrors();
      const response = await Auth.forgotPassword(username);
      console.log(response);
      setForgotPasswordUsername(username);
      return true;
    } catch (err: any) {
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
      return false;
    }
  };

  const forgotPasswordSubmit = async (values: any) => {
    try {
      const { username, code, password } = values;
      console.log(values);
      clearErrors();
      const response = await Auth.forgotPasswordSubmit(
        username,
        code,
        password
      );
      console.log(response);
      return true;
    } catch (err: any) {
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
      return false;
    }
  };

  const refreshUser = async (nav: boolean) => {
    try {
      clearErrors();
      const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const user = await parseUser(currentUser);

      handleUser(user);
      if (nav) {
        navigate("/");
      }
      return true;
    } catch (err: any) {
      console.log(err);
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
      return false;
    }
  };

  const signUp = async (values: UserSignUpParams) => {
    try {
      clearErrors();
      const user = await Auth.signUp({
        username: values.username,
        password: values.password!,
        attributes: {
          email: values.email,
        },
        autoSignIn: {
          enabled: true,
        },
      });
      console.log("Signed up a user");
      console.log(user);

      setUser(values);
      return true;
    } catch (err: any) {
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
      return false;
    }
  };

  const verifyAttribute = async (attribute: string, code: string) => {
    try {
      clearErrors();
      await Auth.verifyCurrentUserAttributeSubmit(attribute, code);

      const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const user = await parseUser(currentUser);
      setUser(user);

      return true;
    } catch (err: any) {
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
      return false;
    }
  };

  const confirmSignUp = async (username: string, code: string) => {
    try {
      clearErrors();
      console.log('confirming');
      await Auth.confirmSignUp(username, code, {
        forceAliasCreation: false,
      });

      return true;
    } catch (err: any) {
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
      return false;
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      clearErrors();
      setTempUsername(username);
      const nextUser = await Auth.signIn(username, password);
      const user = await parseUser(nextUser);

      handleUser(user);
      navigate("/");
      return true;
    } catch (err: any) {
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
      return false;
    }
  };

  const signOut = async () => {
    try {
      clearErrors();
      await Auth.signOut();
      setUser(null);
      setLoggedIn(false);
    } catch (err: any) {
      const nextErrors = errors.concat([err.message]);
      setErrors(nextErrors);
    }
  };

  const superSignOut = async () => {
    try {
      await signOut();
      navigate("/auth/signin");
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const getIdToken = async () => {
    try {
      const currentSession = await Auth.currentSession();
      return currentSession.getIdToken().getJwtToken();
    } catch (err) {
      return undefined;
    }
  };

  const getAccessToken = async () => {
    try {
      const currentSession = await Auth.currentSession();
      return currentSession.getAccessToken().getJwtToken();
    } catch (err) {
      return undefined;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authenticating,
        signIn,
        signOut,
        superSignOut,
        signUp,
        confirmSignUp,
        user,
        accessToken,
        loggedIn,
        errors,
        clearErrors,
        verifyAttribute,
        setErrors: handleErrors,
        updateUser,
        refreshUser,
        changePassword,
        forgotPassword,
        forgotPasswordSubmit,
        forgotPasswordUsername,
        updatePicture,
        getIdToken,
        getAccessToken,
        tempUsername,
        waitForUserGroup,
        waitForUserAttribute,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
