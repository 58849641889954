import env from "react-dotenv";
import { Typography } from "@mui/material";
import { useContext, useState } from "react";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import { AuthContext } from "../Auth/AuthProvider";
import LoadingIcons from "react-loading-icons";
import { ErrorToast } from "../Auth/ErrorToast";
import {
  PlanDetailType,
  SquareSubscriptionPlanType,
  useGetPlanDetailsQueryGenerated,
  useProcessSubscriptionMutationGenerated,
} from "../types/generated";
import { TokenResult } from "@square/web-payments-sdk-types";
import TOS_GOLD from "../assets/TOS_GOLD.svg";
import {useNavigate} from "react-router-dom";

export function Subscribe() {
  const { user, waitForUserGroup } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const { errors: authErrors, setErrors } = useContext(AuthContext);
  const navigate = useNavigate();

  const appId = env.SQUARE_APP_ID;
  const locationId = env.SQUARE_LOCATION_ID;

  const [planType, setPlanType] = useState<SquareSubscriptionPlanType>(
    SquareSubscriptionPlanType.MONTHLY
  );

  const [processSubscription] = useProcessSubscriptionMutationGenerated({
    fetchPolicy: "network-only",
  });

  const buildRequestBody = (props: TokenResult) => ({
    givenName: user?.given_name,
    familyName: user?.family_name,
    emailAddress: user?.email,
    card: props.details?.card,
    sourceId: props.token ?? "",
    planType,
  });

  const handlePlanType = (planType: SquareSubscriptionPlanType) => {
    setPlanType(planType);
  };

  const onPayment = async (props: TokenResult) => {
    try {
      setLoading(true);
      setLoadingMessage("Processing your payment...");
      const payload = buildRequestBody(props);
      await processSubscription({
        variables: { input: payload },
      });
      setLoadingMessage("Unlocking The Other Side...");
      await waitForUserGroup("unlocked");
      if (planType === SquareSubscriptionPlanType.ANNUAL) {
        await waitForUserGroup("gold")
      }
      navigate("/");
    } catch (err: any) {
      setLoading(false);
      const nextErrors = authErrors?.concat([err.message]) || [];
      setErrors(nextErrors);
      console.log(err);
    }
  };

  const { data: planDetails } = useGetPlanDetailsQueryGenerated();

  return (
    <div className="flex min-w-[100vw] flex-col md:min-w-auto">
      <ErrorToast />
      {loading && (
        <div>
          <div style={{ margin: "0 auto", width: "200px" }}>
            <LoadingIcons.Bars width="198px" />
            <Typography variant="h6">{loadingMessage}</Typography>
          </div>
        </div>
      )}

      {!loading && (
        <div className="mb-12 flex w-full flex-col items-center gap-4 px-6">
          <div className="mb-4 flex items-start justify-center space-x-4">
            <div className="flex flex-col">
              <button
                onClick={() =>
                  handlePlanType(SquareSubscriptionPlanType.MONTHLY)
                }
              >
                <div
                  className={`mb-2 flex w-56 items-center justify-center space-x-4 rounded border bg-black px-8 py-4 ${
                    planType === SquareSubscriptionPlanType.MONTHLY
                      ? "border-white"
                      : "border-black"
                  }`}
                >
                  <div className="flex flex-col items-start justify-center  text-white">
                    <Typography variant="h5" color={"white"}>
                      MONTHLY
                    </Typography>
                    <div className="flex items-center justify-center">
                      <Typography variant="h4" color={"white"}>
                        $10
                      </Typography>
                      <Typography variant="h6" color={"white"} marginLeft={0.5}>
                        /month
                      </Typography>
                    </div>
                  </div>
                </div>
              </button>
              {planDetails?.getPlanDetails
                ?.filter((i) => i.type === PlanDetailType.MONTHLY)
                ?.map((feature, index) => (
                  <div className="flex w-56 items-start justify-start text-xs">
                    <div key={`${index}`} className="mr-2">{`\u2022`}</div>
                    <div>{feature.title}</div>
                  </div>
                ))}
            </div>

            <div className="flex flex-col">
              <button
                onClick={() =>
                  handlePlanType(SquareSubscriptionPlanType.ANNUAL)
                }
              >
                <div
                  className={`relative mb-2 flex items-center justify-center space-x-4 rounded border bg-black px-8 py-4 ${
                    planType === SquareSubscriptionPlanType.ANNUAL
                      ? "border-white"
                      : "border-[#8E6C50]"
                  }`}
                >
                  <img
                    src={TOS_GOLD}
                    alt="icon"
                    className="absolute top-0"
                    style={{ marginTop: "-5px" }}
                  />
                  <div className="flex flex-col items-start justify-center  text-white">
                    <Typography variant="h5" color={"white"}>
                      ANNUAL
                    </Typography>
                    <div className="flex items-center justify-center">
                      <Typography variant="h4" color={"white"}>
                        $120
                      </Typography>
                      <Typography variant="h6" color={"white"} marginLeft={0.5}>
                        /year
                      </Typography>
                    </div>
                  </div>
                </div>
              </button>
              {planDetails?.getPlanDetails
                ?.filter((i) => i.type === PlanDetailType.ANNUAL)
                ?.map((feature, index) => (
                  <div className="flex w-56 items-start justify-start text-xs">
                    <div key={`${index}`} className="mr-2">{`\u2022`}</div>
                    <div>{feature.title}</div>
                  </div>
                ))}
            </div>
          </div>

          <div className="pb-16">
            <PaymentForm
              applicationId={appId}
              locationId={locationId}
              cardTokenizeResponseReceived={onPayment}
            >
              <CreditCard
                buttonProps={{
                  css: {
                    fontSize: 16,
                    borderRadius: 40,
                    background:
                      "linear-gradient(180deg, #6DFE95 0%, #6DFE95 30%, #0cb0e8 100%);",
                    fontFamily: "HexFranklinBold",
                    color: "black",
                    textTransform: "none",
                  },
                }}
              >
                COMPLETE PAYMENT
              </CreditCard>
            </PaymentForm>
          </div>
        </div>
      )}
    </div>
  );
}
