import {
  IconButton,
  Typography,
  Modal,
  Dialog,
  Box,
  Button,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useApplyCouponMutationGenerated } from "../types/generated";
import { useContext, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import DiscordHelp from "../assets/discord-help.png";

interface Props {
  open: boolean;
  onDismiss: () => void;
  handleDisplayName: (name: string) => void;
}

export function ChatHelpModal({ open, handleDisplayName, onDismiss }: Props) {
  const { user } = useContext(AuthContext);

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex h-full w-full items-center justify-center bg-[#313338]">
        <Box className="h-{350px} round-4 mx-6  w-fit p-6 ">
          <div className="flex items-center justify-end">
            <IconButton onClick={onDismiss} sx={{ color: "#fff" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="my-4 flex w-full items-center space-x-4">
            <img src={DiscordHelp} />
          </div>
        </Box>
      </div>
    </Dialog>
  );
}
