import { Typography } from "@mui/material";

const questions = [
  {
    image: "",
    heading: "Q&A with Katniss x Lorin",
    sub_heading:
      "Katniss is a resident member of BNTM. She's also a baker, entrepreneur and general badass.",
  },
];

export function QA() {
  return <Typography variant="h4">Q&A Coming Soon...</Typography>;
}

/*
  <div
    className="question-content js-tab-content hidden"
    data-title="Q&A"
  >
    {questions.map((question, index) => {
      return <QAS key={index} question={question} />;
    })}
  </div>
*/
