import { Storage } from "aws-amplify";

export type User = {
  username: string;
  password?: string;
  email: string;
  email_verified?: boolean;
  picture?: string;
  id?: string;
  paid?: boolean;
  groups?: string[];
  given_name?: string;
  family_name?: string;
  accessToken?: string;
  idToken?: string;
  squareCustomerId?: string;
  discordId?: string;
};

export const getPictureUrl = async (user: User): Promise<string> => {
  if (!user.picture) throw new Error("No picture found");
  return await Storage.get(user.picture);
};
