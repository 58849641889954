import { ErrorToast } from "../Auth/ErrorToast";
import { SectionHero } from "../ui/components/SectionHero";
import Avatar from "./Avatar";

const AvatarPage = () => {
  return (
    <div>
      <SectionHero title="Avatar" />
      <ErrorToast />
      <div className="flex h-full w-full flex-col items-center">
        <Avatar />
        <div className="mt-2 cursor-pointer text-xl font-bold text-white">
          Tap to Upload a New Picture
        </div>
        <div className="mt-2 text-sm text-gray-200">
          For best results, use a 250px X 250px image
        </div>
      </div>
    </div>
  );
};

export default AvatarPage;
