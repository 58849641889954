import env from "react-dotenv";
import WidgetBot from "@widgetbot/react-embed";
import { useContext, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { Button, Typography } from "@mui/material";
import { ChatHelpModal } from "./ChatHelpModal";

export function Interact() {
  const { user } = useContext(AuthContext);
  const [showDisplayName, setShowDisplayName] = useState(false);

  const onDismiss = () => {
    setShowDisplayName(false);
  };

  const handleDisplayName = (name: string) => {
    console.log(name);
    setShowDisplayName(false);
  };

  return (
    <div className="flex h-full flex-col">
      {/* <div className="flex w-full items-center justify-end pr-4">
        <Button
          variant="bassnectar"
          className="w-40"
          //startIcon={keyLoading ? <CircularProgress /> : undefined}
          // endIcon={
          //   keyLoading ? undefined : activeSession ? <Launch /> : <KeyIcon />
          // }
          onClick={() => setShowDisplayName(true)}
          //fullWidth
        >
          Display Name
        </Button>
      </div> */}
      {user?.username && (
        <div className="text-nowrap flex w-full items-center space-x-4 pl-4">
          <Typography variant="h5">{`Username: ${user?.username}`}</Typography>
          <Button variant="bassnectar" onClick={() => setShowDisplayName(true)}>
            Help
          </Button>
        </div>
      )}

      <WidgetBot
        server="784687598000930847"
        channel={env.DISCORD_EMBED_CHANNEL}
        width={"100%"}
        height={"100%"}
        shard="https://emerald.widgetbot.io"
        className="mt-4"
        username={user?.username}
      />

      <ChatHelpModal
        open={showDisplayName}
        onDismiss={onDismiss}
        handleDisplayName={handleDisplayName}
      />
    </div>
  );
}
