import {
  IconButton,
  Typography,
  Modal,
  Box,
  Button,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useApplyCouponMutationGenerated } from "../types/generated";
import { useContext, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  onDismiss: () => void;
}

export function CouponCodeModal({ open, onDismiss }: Props) {
  const [applyCoupon, { error }] = useApplyCouponMutationGenerated();
  const [coupon, setCoupon] = useState("");
  const { refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleApplyCoupon = async () => {
    const result = await applyCoupon({
      variables: {
        coupon,
      },
    });
    if (result.data?.applyCoupon) {
      await refreshUser(true);

      setTimeout(() => {
        navigate("/", { replace: true });
      }, 1000);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex h-full w-full items-center justify-center">
        <Box className="h-{350px} tos-body-bkg round-4 mx-6  w-fit p-6">
          <div className="flex items-center justify-between">
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Apply Coupon Code
            </Typography>
            <IconButton onClick={onDismiss} sx={{ color: "#fff" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              variant="filled"
              onChange={(e) => setCoupon(e.target.value)}
              label="Coupon"
              value={coupon}
              error={error != undefined}
            />
            {error && (
              <Typography variant="body1">
                Sorry we were not able to apply your code.
              </Typography>
            )}
          </div>
          <Button variant="bassnectar" onClick={handleApplyCoupon} fullWidth>
            Submit
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
